import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_theory_img_positive = _resolveComponent("theory-img-positive")!
  const _component_theory_img_neutral = _resolveComponent("theory-img-neutral")!
  const _component_theory_img_negative = _resolveComponent("theory-img-negative")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.stats && _ctx.stats.correctlyAnswered > _ctx.stats.incorrectlyAnswered)
      ? (_openBlock(), _createBlock(_component_theory_img_positive, {
          key: 0,
          class: "w-100",
          "student-education": _ctx.selectedEducation,
          stats: _ctx.stats,
          loading: _ctx.loading,
          "learning-mode": _ctx.learningMode
        }, null, 8, ["student-education", "stats", "loading", "learning-mode"]))
      : _createCommentVNode("", true),
    (_ctx.stats && _ctx.stats.correctlyAnswered == _ctx.stats.incorrectlyAnswered)
      ? (_openBlock(), _createBlock(_component_theory_img_neutral, {
          key: 1,
          class: "w-100",
          "student-education": _ctx.selectedEducation,
          stats: _ctx.stats,
          loading: _ctx.loading,
          "learning-mode": _ctx.learningMode
        }, null, 8, ["student-education", "stats", "loading", "learning-mode"]))
      : _createCommentVNode("", true),
    (_ctx.stats && _ctx.stats.correctlyAnswered < _ctx.stats.incorrectlyAnswered)
      ? (_openBlock(), _createBlock(_component_theory_img_negative, {
          key: 2,
          class: "w-100",
          "student-education": _ctx.selectedEducation,
          stats: _ctx.stats,
          loading: _ctx.loading,
          "learning-mode": _ctx.learningMode
        }, null, 8, ["student-education", "stats", "loading", "learning-mode"]))
      : _createCommentVNode("", true)
  ]))
}