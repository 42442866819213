import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_tabs = _resolveComponent("b-tabs")!

  return (_openBlock(), _createBlock(_component_b_tabs, {
    style: {"height":"60px"},
    class: _normalizeClass(["education-tabs", _ctx.colorClass]),
    pills: "",
    "active-nav-item-class": "active-nav-item",
    "model-value": _ctx.initialEducationIndex,
    disabled: "",
    onActivateTab: _ctx.onActiveEducationIndexChange
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.educationClasses, (educationClass) => {
        return (_openBlock(), _createBlock(_component_b_tab, {
          disabled: _ctx.disabled,
          key: educationClass.id,
          title: educationClass.mainLicenseClass
        }, null, 8, ["disabled", "title"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["class", "model-value", "onActivateTab"]))
}