import { Options, Vue, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";

const StudentEducationModule = namespace("student-education");
@Options({})
export default class StudentTheoryEducationMixin extends Vue {
  @StudentEducationModule.Action("selectTheoryStudentEducation")
  public selectTheoryStudentEducation: any;

  @StudentEducationModule.Action("setLearningMode")
  public setLearningModeAction: any;

  @StudentEducationModule.Getter("getSelectedTheoryStudentEducation")
  public selectedEducation: any;

  @StudentEducationModule.Getter("getLearningMode")
  public learningMode: any;

  protected selectedEducationIndex = 0;

  @Watch("studentEducations", { immediate: true, deep: true })
  protected onStudentEducationsChange(studentEducations: Array<any>): void {
    if (studentEducations && studentEducations.length) {
      if (!this.selectedEducation || !this.selectedEducation.id) {
        this.selectEducation(studentEducations[0]);
        this.selectedEducationIndex = 0;
      } else {
        const index = studentEducations.findIndex((se: any) => se.id == this.selectedEducation.id);
        this.selectedEducationIndex = index > -1 ? index : 0;
      }
    }
  }

  protected selectEducation(education: any): void {
    this.selectTheoryStudentEducation(education);
  }
}
