
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  components: {},
})
export default class EducationTabs extends Vue {
  @Prop({ type: String, default: "curious-blue" })
  private colorClass!: string;

  @Prop()
  private educationClasses!: any;

  @Prop({ default: () => 0 })
  private initialEducationIndex!: number;

  @Prop({ default: () => false, type: Boolean })
  public disabled!: boolean;

  public mounted() {
    this.$emit("education-class-changed", this.educationClasses[this.initialEducationIndex]);
  }

  private onActiveEducationIndexChange(activeEducationIndex: number, prevIndex: number): void {
    if (activeEducationIndex != prevIndex) {
      this.$emit("education-class-changed", this.educationClasses[activeEducationIndex]);
    }
  }
}
