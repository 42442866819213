
import { defineComponent } from "vue";

export default defineComponent({
  name: "TransparentSvgRect",
  setup() {
    return {
      clikableRangeW: "110",
      clikableRangeH: "110",
      clikablePositionX: "-12.5",
      clikablePositionY: "-12.5",
    };
  },
});
