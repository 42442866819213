import isEqual from "lodash/isEqual";
async function setVideoSrc(el: HTMLElement, binding: any, vNode: any) {
  const baseURL = process.env.VUE_APP_BACKEND_BASE_URL + "/";

  if (binding.oldValue === undefined || !isEqual(binding.value, binding.oldValue)) {
    const videoURL = binding.value?.url;
    const videoPosterURL = binding.value?.posterUrl;

    if (!videoURL) {
      throw new Error("[vue-video] please set url.");
    }

    if (videoPosterURL instanceof Promise) {
      const videoPoster = await videoPosterURL;

      if (videoPoster.status == "success") {
        el.setAttribute("poster", videoPoster.src);
      } else {
        el.setAttribute("poster", baseURL + videoPoster.src);
      }
    } else {
      el.setAttribute("poster", baseURL + videoPosterURL);
    }

    if (videoURL instanceof Promise) {
      const video = await videoURL;
      if (video.status == "success") {
        el.setAttribute("src", video.src);
      } else {
        el.setAttribute("src", baseURL + video.src);
      }
    } else {
      el.setAttribute("src", baseURL + videoURL);
    }
  }
}

export default class Video {
  public beforeMount(el: HTMLElement, binding: any, vNode: any) {
    setVideoSrc(el, binding, vNode);
  }

  public updated(el: HTMLElement, binding: any, vNode: any) {
    setVideoSrc(el, binding, vNode);
  }
}
