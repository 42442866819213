import { Options } from "vue-property-decorator";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";

@Options({})
export default class MediaQuestionMixin extends QuestionMixin {
  public get authMediaUrl(): string {
    if (this.question) {
      return `theory-questions/${this.question.id}/media`;
    }
    return "";
  }

  public get authStartThumbUrl(): string {
    if (this.question) {
      return `theory-questions/${this.question.id}/media?thumbnail=START`;
    }
    return "";
  }

  public get authEndThumbUrl(): string {
    if (this.question) {
      return `theory-questions/${this.question.id}/media?thumbnail=END`;
    }
    return "";
  }
}
