import { Directory, Filesystem } from "@capacitor/filesystem";
import write_blob from "capacitor-blob-writer";
import { Capacitor } from "@capacitor/core";
import { MediaTypeEnum } from "@/enums/MediaTypeEnum";
import { IQuestion } from "@/interfaces/IQuestion";
import last from "lodash/last";
import useQueryString from "@/composable/useQueryString";

const dir = Directory.Cache;

export default () => {
  const readFile = async (path: string, folder = "theory_questions/") => {
    return await Filesystem.readFile({
      path: folder + path,
      directory: dir,
    });
  };

  const { updateParam } = useQueryString();

  const readDir = async (path = "theory_questions/") => {
    return await Filesystem.readdir({
      path,
      directory: dir,
    });
  };

  const deleteDir = async (path = "theory_questions/") => {
    return await Filesystem.deleteFile({
      path,
      directory: dir,
    });
  };

  const writeFile = (blob: Blob, path: string, folder = "theory_questions/") => {
    return write_blob({
      blob: blob,
      path: folder + path,
      directory: dir,
      recursive: true,
      fast_mode: true,
    });
  };

  const pathBuilder = (id: number, officialNumber: string, extension: string, reduced = false, suffix = "") => {
    let rs = "";
    let sfix = "";

    if (reduced) {
      rs = "_rs";
    }

    if (suffix) {
      sfix = suffix;
    }

    return `${id}_${officialNumber}${rs}${sfix}.${extension}`;
  };

  const determinateMediaStartOrEnd = (url?: string, defaultOnFallback = "") => {
    const thumbnail = url?.split("thumbnail")?.[1]?.replace("=", "");

    if (thumbnail == "START") {
      return "_start";
    } else if (thumbnail == "END") {
      return "_end";
    }
    return defaultOnFallback;
  };

  const getSuffix = (url: string | null, defaultOnFallback = "") => {
    if (url == "START") {
      return "_start";
    } else if (url == "END") {
      return "_end";
    }
    return defaultOnFallback;
  };

  const fileIsset = async (path: string): Promise<boolean> => {
    try {
      await readFile(path);
      return true;
    } catch (exception) {
      return false;
    }
  };

  const mediaTypeByExtension = (extension: string) => {
    switch (extension) {
      case "jpg":
        return MediaTypeEnum.jpg;
      case "jpeg":
        return MediaTypeEnum.jpeg;
      case "png":
        return MediaTypeEnum.png;
      default:
        return MediaTypeEnum.mp4;
    }
  };

  const extensionByMediaType = (mediaType: MediaTypeEnum) => {
    switch (mediaType) {
      case MediaTypeEnum.jpg:
        return "jpg";
      case MediaTypeEnum.jpeg:
        return "jpeg";
      case MediaTypeEnum.png:
        return "png";
      default:
        return MediaTypeEnum.mp4;
    }
  };

  const getMediaFromFilesystem = (question: IQuestion, src?: string, webRs = true, thumbnail = true) => {
    if (Capacitor.isNativePlatform()) {
      let extension: string;
      let reduced: boolean;

      const suffix = determinateMediaStartOrEnd(src);

      if (suffix == "_end") {
        reduced = Boolean(question.reducedSizeThumbnailEndFileName);
        extension = pathToExtension(question.reducedSizeThumbnailEndFileName);
      } else if (suffix == "_start") {
        reduced = Boolean(question.reducedSizeThumbnailStartFileName);
        extension = pathToExtension(question.reducedSizeThumbnailStartFileName);
      } else {
        reduced = Boolean(question.reducedSizeFileName);
        extension = pathToExtension(question.reducedSizeFileName);
      }

      const query = updateThumbnailParam({}, thumbnail, question);
      const updateSrc = updateParam(src || "", query);
      const newSrc = updateParam(updateSrc || "", { reducedSize: reduced });

      try {
        const mediaType = mediaTypeByExtension(extension);

        const path = pathBuilder(question.id, question.officialNumber, extension, reduced, suffix);

        return readFile(path)
          .then((response: any) => {
            const base64 = `data:${mediaType};base64,`;
            return {
              src: base64 + response.data,
              status: "success",
            };
          })
          .catch(() => {
            return {
              src: newSrc,
              status: "failed",
            };
          });
      } catch (e) {
        return newSrc;
      }
    }

    const query = updateThumbnailParam({ reducedSize: webRs }, thumbnail, question);

    return updateParam(src || "", query);
  };

  const updateThumbnailParam = (query: Record<any, any>, isThumbnail: boolean, question: IQuestion) => {
    if (!isThumbnail && question.mediaType != MediaTypeEnum.mp4) {
      Object.assign(query, {
        thumbnail: "",
      });
    }
    return query;
  };

  const pathToExtension = (path?: string, fallbackExtension = "mp4") => {
    const explode = path?.split(".");
    return last(explode) || fallbackExtension;
  };

  return {
    readFile,
    readDir,
    writeFile,
    deleteDir,
    pathBuilder,
    determinateMediaStartOrEnd,
    fileIsset,
    getMediaFromFilesystem,
    pathToExtension,
    extensionByMediaType,
    getSuffix,
  };
};
