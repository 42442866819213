import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rect", {
    width: _ctx.clikableRangeW,
    height: _ctx.clikableRangeH,
    rx: "10",
    fill: "#ffffff",
    "fill-opacity": "0.0",
    stroke: "none",
    x: _ctx.clikablePositionX,
    y: _ctx.clikablePositionY
  }, null, 8, _hoisted_1))
}