import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "row justify-content-center",
  style: {"margin-top":"50px","margin-bottom":"20px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchoolLogo = _resolveComponent("SchoolLogo")!
  const _component_TrainingBlockHeader = _resolveComponent("TrainingBlockHeader")!
  const _component_TrainingBlock = _resolveComponent("TrainingBlock")!
  const _component_FragenBlock = _resolveComponent("FragenBlock")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_GuestLayout = _resolveComponent("GuestLayout")!

  return (_openBlock(), _createBlock(_component_GuestLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_col, {
          cols: "10",
          sm: "8",
          md: "6",
          lg: "4",
          class: "justify-content-center align-items-center d-flex flex-column"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SchoolLogo, { "driving-school-id": _ctx.drivingSchoolId }, null, 8, ["driving-school-id"]),
            _createVNode(_component_TrainingBlockHeader),
            _createVNode(_component_TrainingBlock),
            _createVNode(_component_FragenBlock)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}