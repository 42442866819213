
import { computed, defineComponent, PropType } from "vue";
import Image from "@/directives/Image";
import useFile from "@/composable/useFile";
import { IQuestion } from "@/interfaces/IQuestion";

export default defineComponent({
  name: "FscImage",
  emits: ["click", "load", "error", "loading"],
  directives: {
    image: new Image(),
  },
  props: {
    src: {
      type: String,
      required: false,
    },
    question: {
      type: Object as PropType<IQuestion>,
      required: true,
    },
    rs: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    thumbnail: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const { getMediaFromFilesystem } = useFile();

    const onLoaded = () => {
      emit("load");
    };

    const onError = () => {
      emit("error");
    };

    const onLoading = () => {
      emit("loading");
    };

    const onClick = () => {
      emit("click");
    };

    const mediaURL = computed(() => {
      return getMediaFromFilesystem(props.question, props.src, props.rs, props.thumbnail);
    });

    return {
      onLoaded,
      onError,
      onLoading,
      onClick,
      mediaURL,
    };
  },
});
