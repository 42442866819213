import queryString from "query-string";

export default () => {
  const updateParam = (path: string, query: Record<any, any>) => {
    return queryString.stringifyUrl({
      url: path,
      query,
    });
  };

  return {
    updateParam,
  };
};
